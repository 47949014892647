.selectContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
}

.label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

.selectWrapper {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid var(--black);
  /* border-radius: 4px; */
  padding: 12px 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  min-width: 240px;
}

.arrow {
  border-left: 1px solid #ddd;
  padding: 5px 0 5px 8px;
}

.selectedValue {
  color: #333;
}

.optionsContainer {
  position: absolute;
  top: calc(100% + 4px);
  left: -1px;
  right: 0;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 100%;
  overflow-y: auto;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    visibility 0s linear 0.3s;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}

.optionsContainerOpen {
  max-height: 300px;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.option {
  padding: 8px 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #f7f7f7;
}
